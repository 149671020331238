// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;


//html, body { height: calc(100% - 60px); background-color: #262626; } // Dark theme
html, body { height: 100%; }
body { margin: 0; padding: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }





/* ----- Buttons ----- */

.base_buttons{
  color: #FFF;
  vertical-align: middle;
  min-width: 120px !important;
  min-height: 35px !important;
  margin: 5px;
  text-transform: uppercase;
  font-size: 14px;
}
.base_buttons:disabled{
  background-color: #00618188 !important;
  border: #00618188;
  color: #FFF !important;
  border-radius: 4px;
}
.base_buttons:enabled{
  border-radius: 4px;
}
.base_buttons:hover{
  cursor: pointer;
}
.full_width{
  width: 100%;
}
@media (max-width: 1500px) {
  .base_buttons {
      font-size: 12px; 
  }
}






// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();




$custom-primary: (
   50 : #e1f3f8,
   100 : #b3e1ed,
   200 : #81cde1,
   300 : #4fb8d5,
   400 : #29a7cc,
   500 : #0085a3,
   600 : #007a99,
   700 : #006d8d,
   800 : #006181,
   900 : #004f6e,
   A100 : #b3e1ed,
   A200 : #81cde1,
   A400 : #29a7cc,
   A700 : #004f6e,
   contrast: (
       50 : #000000,
       100 : #000000,
       200 : #000000,
       300 : #000000,
       400 : #000000,
       500 : #ffffff,
       600 : #ffffff,
       700 : #ffffff,
       800 : #ffffff,
       900 : #ffffff,
       A100 : #000000,
       A200 : #000000,
       A400 : #000000,
       A700 : #ffffff,
   )
);

// Set each color pallete
//  Primary
$my-primary: mat.define-palette($custom-primary);
//  Secondary
//$my-accent: mat.define-palette($custom-primary);
//  Warm (optional and defaults to red if not specified)
//$my-warn: mat.define-palette($custom-primary);

// Apply the configuration
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-primary,
   //primary: mat.define-palette(mat.$pink-palette),
   //accent: mat.define-palette(mat.$blue-grey-palette),
   warn: $my-primary,
 ),
 //typography: mat.define-typography-config(),
 //density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);
// Set style for all component
@include mat.all-component-themes($my-theme);

// Styles can also be set individually for each component. I.E. Button.
//@include mat.button-theme($my-theme);